import { BIG_PROXY_CLIENT_URL, BIG_PROXY_PROJECT_ID } from "./env";

const DEFAULT_LANGUAGE = "EN";

export const getDefaultGetLoginParams = () => ({
  successURL: window.location.href,
  successURLHash: "",
  failureURL: window.location.href,
  language: DEFAULT_LANGUAGE,
  destination: "",
});

export const getLoginUrl = (
  _params: {
    successURL?: string;
    successURLHash?: string;
    failureURL?: string;
    language?: string;
    destination?: string;
  } = getDefaultGetLoginParams()
) => {
  const params = { ...getDefaultGetLoginParams(), ..._params };
  const successURL = params.successURL || window.location.href;

  let language = params.language || DEFAULT_LANGUAGE;
  if (language.length < 3) {
    language = language.toLowerCase();
  }

  const search = new URLSearchParams({
    successURL: successURL + params.successURLHash,
    failureURL: params.failureURL || successURL,
    language,
    projectId: String(BIG_PROXY_PROJECT_ID),
  });

  return `${BIG_PROXY_CLIENT_URL}${params.destination}?${search}`;
};
