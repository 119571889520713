import React, { FC, useCallback, useEffect } from "react";
import { ls } from "src/utils";
import * as service from "src/service";
import { useDispatch, batch, useSelector } from "react-redux";
import { authorization, settings } from "src/store";
import { useToggle } from "@big-proxy/ui";

interface IAuthProvider {
  children: React.ReactNode;
}

type IContextValue = { onReload: () => Promise<boolean> };
export const authContext = React.createContext<IContextValue>({
  onReload: async () => false,
});

const AuthProvider: FC<IAuthProvider> = ({ children }) => {
  const dispatch = useDispatch();
  const [isInitializing, setIsInitializing] = useToggle(true);
  const searchParams = new URLSearchParams(window.location.search);

  const resetUser = () => {
    dispatch(authorization.actions.setIsAuthorized(false));
    dispatch(authorization.actions.setUser({}));
    dispatch(authorization.actions.setSubscription({}));
    ls.user.remove();
  };

  const onConfirmToken = useCallback(
    async (token: string) => {
      const { response, data } = await service.auth.check_token(token);
      if (response.ok) {
        batch(() => {
          dispatch(authorization.actions.setIsAuthorized(data.message.validation));
          dispatch(authorization.actions.setUser(data.message.user));
        });

        return true;
      }

      batch(resetUser);
      return false;
    },
    [dispatch]
  );

  const onReloadTokenInfo = useCallback(() => {
    return onConfirmToken(ls.token.get());
  }, [onConfirmToken]);

  useEffect(() => {
    if (searchParams.get("token")) {
      ls.token.set(searchParams.get("token"));
      searchParams.delete("token");

      const url = new URL(window.location.href);
      url.search = searchParams.toString();
      location.href = url.toString();

      return;
    }

    setIsInitializing.off();

    const token = ls.token.get();
    if (token) {
      onConfirmToken(token);
    } else {
      ls.token.remove();
      batch(resetUser);
    }

    return ls.token.listen((token) => {
      if (token) {
        return onConfirmToken(token);
      }

      batch(resetUser);
    });
  }, []);

  return (
    <authContext.Provider value={{ onReload: onReloadTokenInfo }}>
      {isInitializing ? null : children}
    </authContext.Provider>
  );
};

export const withAuth = (Component: FC) => {
  const WrappedComponent = (props: React.ComponentProps<typeof Component>) => {
    const isAuthorized = useSelector(authorization.selectors.getIsAuthorized);
    const language = useSelector(settings.selectors.getLanguage);
    useEffect(() => {
      if (isAuthorized === false) {
        window.location.href = window.location.origin + '/admin/login?destination=' + window.location.href;
        return;
      }
    }, [isAuthorized]);

    return isAuthorized === true 
    ? <Component {...props} /> 
    : null
  }

  WrappedComponent.displayName = `withAuth`;

  return WrappedComponent;
};

export default AuthProvider;
